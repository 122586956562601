
const tables = {
    state: {
        tables: {}
    },
    mutations: {
        setTable(state, {table, values}) {
            state.tables[table] = values;
            state.tables = Object.assign({}, state.tables);
        },
        updateTable(state, {table, record}) {
            let records = state.tables[table];
            if (!records) return;
            let i = _.findIndex(records, (c)=>c.id==record.id);
            if (i>-1) {
                state.tables[table][i] = Object.assign({}, record);
            } else {
                state.tables[table].push(record);
            }
            state.tables[table] = Object.assign([], state.tables[table])
        },
        removeRecord(state, {table, id}) {
            let records = state.tables[table];
            if (!records) return;
            let i = _.findIndex(records, (c)=>c.id==id);
            if (i>=0) {
                state.tables[table].splice(i, 1);
                state.tables[table] = Object.assign([], state.tables[table]);
            }
        },
    },
    actions: {
        async setTable ({commit, state}, table) {
            let values = await api.get('/api/' + table + '/?IncludeClosed=true');
            commit('setTable', {table, values});
        },
    }
}

export default tables;
