<template>
  <div class="modal fade" id="flightInfo" tabindex="-1" role="dialog" aria-labelledby="flightInfoLabel"
      data-backdrop="static" aria-hidden="true" @keyup.27="$emit('close')">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmDeleteLabel">{{tr('Flight Information')}}</h5>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{flight}}
        </div>
        <!--div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'confirm-close',
    props: ['flight'],
    mounted () {
        $('#flightInfo').modal('show');
    },
    methods: {
        close () {
            $('#flightInfo').modal('hide');
            this.$emit('close');
        }
    },

}
</script>
