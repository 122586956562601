

class Cache {

    constructor() {
        this.objects = {}
    }

}

window._cache = new Cache();
