<template>
    <div class="app-index">
        <block-ui v-if="processing"/>
        <div class="container">
            <label>Segments</label>
            <div class="row">
                <div class="col-3 mt-3 form-row" v-for="key of list">
                    <input
                        v-model="segments[key].from_station"
                        class="col-2 form-control inpc"
                        type="text">
                    <input
                        v-model="segments[key].to_station"
                        class="col-2 form-control inpc"
                        type="text">
                </div>
            </div>
            <div class="row mt-3">
                <button type="button" class="btn btn-primary" @click="add">{{tr('New Segment')}}</button>
                <button type="button" class="btn btn-primary" @click="save">{{tr('Save')}}</button>
            </div>
            <div class="row mt-3" v-if="addNew">
                <div class="col-12 mt-3 form-row">
                    <input
                        v-model="new_from_station"
                        class="col-1 form-control inpc"
                        type="text">
                    <input
                        v-model="new_to_station"
                        class="col-1 form-control inpc"
                        type="text">
                </div>
                <div class="col-12 form-row">
                    <button type="button" class="btn btn-primary"
                        :disabled="!new_from_station || !new_to_station"
                        @click="addSegment">{{tr('Add')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'segments',
    data () {
        return {
            addNew: false,
            processing: false,
            list: [],
            segments: {},
            new_from_station: null,
            new_to_station: null,
        }
    },
    async mounted () {
        this.processing = true;
        this.segments = await api.get_s3('segments.json', true);
        this.list = Object.keys(this.segments);
        this.list.sort((a, b)=>{
            if ( a < b ){
                return -1;
            }
            if ( a > b ){
                return 1;
            }
            return 0;
        })
        this.processing = false;
    },
    methods: {
        async save () {
            this.processing = true;
            let res = await api.save_s3('segments.json', this.segments);
            this.processing = false;
        },
        add () {
            this.addNew = true;
        },
        addSegment () {
            let key = this.new_from_station + this.new_to_station;
            this.segments[key] = {from_station: this.new_from_station, to_station: this.new_to_station}
            this.list.push(key);
            this.new_to_station = null;
            this.new_from_station = null;
            this.segments = Object.assign({}, this.segments);
        }
    },
}
</script>

