<template>
    <div class="container-full">
        <div :id="_uid " style="width:100%;"></div>
    </div>
</template>


<script>
export default {
    name: 'tableau',
    props: ['options'],
    data () {
        return {
            vizList: {},
            interval: null,
        }
    },
    mounted () {
        this.showReport();
        this.interval = setInterval(() => {
            let i = $('#' + this._uid).find('iframe')
            i.width('100%');
            let h = '900px';
            if (this.options.height) h = this.options.height;
            i.height(h);
        }, 100);
    },
    methods: {
        showReport () {
            this.viz(this._uid, this.options.url)
        },
        viz (id, url) {
            if (this.vizList[id]) return;
            let containerDiv = document.getElementById(id),
            options = {
                hideTabs: true,
                device: 'desktop',
                onFirstInteractive: function () {
                    console.log("Run this code when the viz has finished loading.");
                }
            };
            this.vizList[id] = new tableau.Viz(containerDiv, url, options);
        }
    },
    beforeDestroy () {
        clearInterval(this.interval);
    }
}
</script>
