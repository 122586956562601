<template>
    <section class="home-section">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <report-window
                endpoint="/reports/rec_summary"
                :perPage="50"
                :fields="fields"
                :title="title"
                :headers="headers"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
                :columnsClasses="columnsClasses"
            ></report-window>
            <flight-info
                v-if="flight"
                :flight="flight"
                @close="flight = null"
            ></flight-info>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import FlightInfo from "@/components/flight/FlightInfo";
const moment = require("moment");
export default {
    name: "rec-data-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
        'flight-info': FlightInfo,
    },
    mounted: function() {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
    },
    data() {
        return {
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: "FileDate",
                    editor: "date",
                    label: "File Date",
                    columns: 2,
                    required: true,
                    defValue: moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightNumber",
                    label: "Flight Designator",
                    columns: 2,
                },
                {
                    name: "FlightFromDate",
                    editor: "date",
                    label: "Flight Date From",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightToDate",
                    editor: "date",
                    label: "Flight Date To",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "Origin",
                    editor: "text",
                    label: "Origin",
                    columns: 2
                },
                {
                    name: "Destination",
                    editor: "text",
                    label: "Destination",
                    columns: 2
                },
                {
                    name: "Lids",
                    editor: "select",
                    label: "Show",
                    columns: 2,
                    options: [
                        { label: 'All', value: '0'}, { label: 'Sent Lids', value: '1' }
                    ],
                    defValue: '1'
                },
            ],
            columnsClasses: {
                Total: 'font-weight-bold text-right',
                100: 'text-right',
                101: 'text-right',
                102: 'text-right',
                103: 'text-right',
                104: 'text-right',
                105: 'text-right',
                106: 'text-right',
                107: 'text-right',
                108: 'text-right',
                109: 'text-right',
                110: 'text-right',
            },
            title: "Recommendations Summary",
            headers: {
            },
            templates: [

            ],
            columnClick: {
            },
            fieldClasses: {
                'Total': this.totalClassRow,
                100: this.totalClassRow,
                101: this.totalClassRow,
                102: this.totalClassRow,
                103: this.totalClassRow,
                104: this.totalClassRow,
                105: this.totalClassRow,
                106: this.totalClassRow,
                107: this.totalClassRow,
                108: this.totalClassRow,
                109: this.totalClassRow,
                110: this.totalClassRow,
            },
        };
    },
    methods: {
        totalClassRow (row) {
            if (row.Market == 'Total') return 'font-weight-bold';
        }
    }
};
</script>
