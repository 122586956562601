
import BaseModel from '@/base/model/base-model';

class Record extends BaseModel {

    constructor({tableName, options, endpoint, record, gettersNames}) {
        super({fields: options.fields, record, gettersNames, tableName});
        let self = this;
        return (async () => {
            let initPromise = new Promise((resolve, reject) => {
                resolve(self);
            })
            let user = await api.getCurrentUser();
            self = await initPromise;
            self.tableName = tableName;
            self.showClosed = false;
            self.serverSide = options.serverSide;
            self.endpoint = endpoint? endpoint: '/api/' + tableName + '/';
            self.search = null;
            self.initNotifications();
            self.attached = [];
            self.attachList = [];
            self.beforeSave = options.beforeSave;
            self.canAdd = tools.canAccess(user, 'api','POST', self.endpoint);
            self.userCanEdit = tools.canAccess(user, 'api','POST', self.endpoint);
            self.userCanDelete = tools.canAccess(user, 'api','DELETE', self.endpoint);
            self.evalDelete = options.evalDelete;
            self.evalEdit = options.evalEdit;
            //self.afterEdit = options.afterEdit;
            self.me = user;
            self.processingAttach = false;
            self._modified = false;
            return self;
        })();
    }

    getHasClosed () {
        for (let f of this.fields) {
            if (f === 'Closed' || f.name === 'Closed') return true;
        }
        return false;
    }

    async save () {
        if (this.beforeSave) {
            let res = this.beforeSave(this);
            if (res) {
                await this.init(res);
                return;
            }
        }
        return await this.saveRecord();
    }

    async remove () {
        this.initNotifications();
        let self = this;
        let res = await api.remove(this.endpoint, this.id , (response) => {
            this.addError(response)
        });
        if (res) {
            api.removeFromStore(this.tableName, this.id);
            return res;
        }
    }

    async saveRecord () {
        let required = this.checkFields();
        if (required) {
            api.setProcessing(false)
            return;
        }
        this.initNotifications();
        var r = _.cloneDeep(this);
        tools.cleanBeforeSave(r);
        r = tools.plainObject(r);
        let result = await api.save(this.endpoint, r, this.attachList, (response) => {
            this.addError(response);
            api.setProcessing(false);
        });
        if (result) {
            await this.init(result)
            this.original_id = this.id;
            api.updateTable(this.tableName, result);
            this.addNotification("Record Saved");
            if (result._warnings) {
                this.notifications.warning = result._warnings.join('; ');
            }
            return true;
        }
    }

    addNotification (msg) {
        this.notifications.message = msg;
        this.notifications.errors = [];
        setTimeout(() => {
            this.notifications.message = null;
        }, 5000);
    }

    initNotifications () {
        this.notifications = {
            errors: [],
            message: null,
            warning: null,
        }
    }

    addError (msg) {
        if (this.notifications.errors.indexOf(msg)==-1) {
            this.notifications.errors.push(msg);
            this.notifications.message = null;
        }
    }

    get titleName () {
        if (this.Name && this.id) return this.id + " - " + this.Name;
        if (this.Name) return this.Name;
        return '';
    }

    get canDelete () {
        if (!this.userCanDelete) return false;
        if (this.evalDelete) {
            return this.evalDelete(this);
        }
        return true
    }

    get canEdit () {
        if (!this.userCanEdit) return false;
        if (this.evalEdit) {
            return this.evalEdit(this);
        }
        return true
    }

    async getAttached () {
        if (this && this.id) {
            this.processingAttach = true;
            let res = await api.get('/api/get_attach_list/' + this.tableName + '/' + this.id);
            if (res) {
                this.attached = res;
                this.processingAttach = false;
            }
        } else {
            this.attached = [];
        }
    }

    static async get ({id, tableName, options, endpoint}) {
        let record = await api.getObject(tableName, id);
        if (record) {
            let r = await new this({tableName, options, endpoint, record});
            if (this.id) {
                this.getAttached();
            }
            return r;
        }
    }



}

export default Record;
