import main from '@/store/main';
import tables from '@/store/tables';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        main: main,
        tables: tables,
    }
})

export default store;
