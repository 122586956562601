class View {

    static options = {
        fieldsOrder: ['Name']
    }

    static get serverSide () {
        return process.env.SERVER_SIDE[this.table];
    }

    static async getTableOptions (options) {
        let res = options;
        if (!res) {
            res = {
                title: this.table,
                canAdd: true,
            }
        }
        res.fieldsOrder = this.options.fieldsOrder;
        if (!res.fields) {
            let serverFields = await api.get('/api/get_table_fields/' + this.table);
            res.fields = tools.serverTypes(serverFields, res)
        }
        return res;
    }

}

export default View;
