
const serverEndpoint = process.env.SERVER_ENDPOINT;

class Api {

    constructor () {
        this.tables = {};
        this.tableOptions = {};
        this.currentUser = null;
        this.processing = false;
        this.serverEndpoint = serverEndpoint;
        this.logName = process.env.LOGO_NAME;
        this.user = null;
    }

    logout () {
        this.currentUser = null;
    }

    setProcessing (value) {
        this.processing = value;
    }

    setTableOptions (table, options) {
        this.tableOptions[table] = options;
    }

    async getCurrentUser () {
        let res = this.currentUser;
        if (!res) {
            res = await this.get('/current_user');
            this.currentUser = res;
        }
        return res;
    }

    getTableParams (table) {
        let self = this;
        return (async () => {
            let module = await importMixinModule(table, 'tables');
            let options = await module.getTableOptions()
            self.tableOptions[table] = options;
            return options;
        })();
    }

    async getObject (table, id) {
        let res;
        if (this.tables[table]) {
            let res = _.find(this.tables[table], (r) => r.id == id);
            if (res) {
                let sync_version = await api.get('/api/get_sync_version/' + table + '/' + id );
                if (sync_version && sync_version != res.sync_version) {
                    let res2 = await api.get('/api/' + table + '/' + id);
                    if (res2)  {
                        res2.original_id = id;
                        this.updateTable(table, res2);
                        return res2;
                    }
                } else {
                    return res;
                }
            }
        }
        res = await api.get('/api/' + table + '/' + id);
        if (res) return res;
    }

    getTable (table) {
        if (this.tables[table]!=null) {
            return this.tables[table];
        }
    }

    setTable (table, values) {
        this.tables[table] = values;
    }

    updateTable (table, record) {
        let records = this.tables[table];
        if (!records) return;
        let i = _.findIndex(records, (c)=>c.id==record.id);
        if (i>-1) {
            this.tables[table][i] = Object.assign({}, record);
        } else {
            this.tables[table].push(record);
        }
        //this.tables[table] = Object.assign([], this.tables[table]);
    }

    removeFromStore (table, id) {
        let records = this.tables[table];
        if (!records) return;
        let i = _.findIndex(records, (c)=>c.id==id);
        if (i>=0) {
            this.tables[table].splice(i, 1);
            //this.tables[table] = Object.assign([], this.tables[table]);
        }
    }


    async getUser() {
        var user = await this.get('/current_user');
        if (user) {
            user.original_id = user.id;
            this.user = user;
            return user;
        }
    }

    async get (e, params, handleError) {
        let endpoint = new URL(serverEndpoint + e);
        if (params) {
            endpoint.search = new URLSearchParams(params).toString();
        }
        return new Promise((resolve, reject) => {
            fetch(endpoint, {
                    method: 'get',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    credentials: 'include',
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        resolve(response.json())
                    } else {
                        let r = tools.responseHelper(response.status, await response.json(), handleError);
                        if (handleError && r) handleError(r);
                    }
                })
                .catch(function(err) {
                    //console.error(err);
                    if (handleError) handleError(err);
                    reject(err);
                });
        })
    }

    async post (endpoint, data, headers, handleError) {
        let self = this;
        let h = headers;
        if (!h) {
            h = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        return new Promise((resolve, reject) => {
            fetch(serverEndpoint + endpoint, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    headers: h,
                    body: data
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        resolve(response.json())
                    } else {
                        let r = tools.responseHelper(response.status, await response.json(), handleError);
                        if (handleError && r) handleError(r);
                        resolve(false)
                    }
                })
                .catch(function(err) {
                    api.setProcessing(false);
                    let r = tools.responseHelper(err, null, handleError);
                    if (handleError && r) handleError(r);
                    resolve(false)
                    console.log('err = ', err);
                    //reject(err);
                });
        })
    }

    async remove (endpoint, id, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            fetch(serverEndpoint + endpoint + id, {
                    method: 'DELETE',
                    mode: 'cors',
                    credentials: 'include',
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        resolve(response.json())
                    } else {
                        let r = tools.responseHelper(response.status, await response.json(), handleError);
                        if (handleError && r) handleError(r);
                    }
                })
                .catch(function(err) {
                    console.log('err = ', err);
                    reject(err);
                });
        })
    }

    save (endpoint, record, attachFiles, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.post(endpoint, JSON.stringify(record), null, handleError)
                .then(async function(response) {
                    if (attachFiles && attachFiles.length>0) {
                        await self.saveFiles(record.tableName, response.id, attachFiles);
                    }
                    resolve(response)
                })
                .catch(function(err) {
                    console.log('err = ', err, handleError);
                    reject(err)
                });
        })
    }

    async saveFiles (tableName, id, attachFiles) {
    	var files = new FormData();
        for (let i in attachFiles) {
            files.append('files[' + i + ']', attachFiles[i])
        }
        return await this.post('/api/' + tableName + '/files/' + id, files, {});
    }


    async get_s3 (fileName, mapping) {
        var res = await this.get(`/api/get_s3/${fileName}`);
        console.log(fileName, res)
        if (mapping) this.replaceCodes(res, mapping);
        return res;
    }

    async save_s3 (fileName, data, mapping) {
        var res = await this.post(`/api/save_s3/${fileName}`, JSON.stringify(data));
        if (mapping) this.setCodes(data, mapping);
        return res;
    }

    async get_markets () {
        let res = await this.get('/api/get_markets');
        for (let i in res) {
            let code = res[i];
            this.tables.mapping.forEach((m, index)=> {
                if (code.includes(m.from_code)) {
                    res[i] = res[i].replace(m.from_code, m.to_code);
                }
            })
        }
        return res;
    }

    replaceCodes (res, fieldNames) {
        if (this.tables.mapping) {
            for (let row of res) {
                for (let fieldName of fieldNames) {
                    if (row[fieldName]) {
                        this.tables.mapping.forEach((m, index)=> {
                            if (m.from_code.length == 3 && row[fieldName].includes(m.from_code)) {
                                row[fieldName] = row[fieldName].replace(m.from_code, m.to_code);
                            }
                            if (m.from_code.length == 2 && row[fieldName] == m.from_code) {
                                row[fieldName] = m.to_code;
                            }
                        })
                    }
                }
                if (row.markets) {
                    this.replaceCodes(row.markets, ['market'])
                }
            }
        }
    }


    setCodes (data, fieldNames) {
        if (this.tables.mapping) {
            for (let row of data) {
                for (let fieldName of fieldNames) {
                    if (row[fieldName]) {
                        this.tables.mapping.forEach((m, index)=> {
                            if (m.to_code.length == 3 && row[fieldName].includes(m.to_code)) {
                                row[fieldName] = row[fieldName].replace(m.to_code, m.from_code);
                            }
                            if (m.to_code.length == 2 && row[fieldName] == m.to_code) {
                                row[fieldName] = m.from_code;
                            }
                        })

                    }
                }
                if (row.markets) {
                    this.setCodes(row.markets)
                }
            }
        }
    }

}

window.api = new Api();
