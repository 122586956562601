//import '@/base/main.app';
import '@/base/index';
import '@/tools/importVueComp';
import '@/tools/components';
import '@/tools/vue-tools';

import Vue from 'vue';
import App from '@/App.vue';
//import 'bootstrap';
import 'bootstrap-datepicker';
//import 'bootstrap-3-typeahead';
//import '@/css/bootstrap.min.css';
//import '@/css/bootstrap-grid.min.css';
import '@/css/bootstrap-datepicker3.min.css';

import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;
import * as _ from 'lodash';
window._ = _;
var moment = require('moment');
window.moment = moment;
import store from '@/store/store';
import router from '@/router/index';

//import VueResource from "vue-resource";
import '@/tools/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);
import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed)
Vue.filter('toLocalDateTime', tools.getLocalDateTime);
Vue.filter('toLocalDate', tools.getLocalDate);
Vue.filter('toDate', tools.getDate);
Vue.filter('toDateTime', tools.getDateTime);

const selectInput = importVueComp('components/tools', 'SelectInput');
Vue.component('select-input', selectInput);
const datePicker = importVueComp('components/tools', 'DatePicker');
Vue.component('date-picker', datePicker);
const numberInput = importVueComp('components/tools', 'NumberInput');
Vue.component('number-input', numberInput);
const vueSelectInput = importVueComp('components/tools', 'VueSelect');
Vue.component('vue-select-input', vueSelectInput);
const iFrame = importVueComp('components/tools', 'IFrame');
Vue.component('i-frame', iFrame);
const navBar = importVueComp('components/tools', 'NavBar');
Vue.component('nav-bar', navBar);
const selectValue = importVueComp('components/tools', 'SelectValue');
Vue.component('select-value', selectValue);
const inputValue = importVueComp('components/tools', 'InputValue');
Vue.component('input-value', inputValue);
const sectionTitle = importVueComp('components/tools', 'SectionTitle');
Vue.component('section-title', sectionTitle);

const blockUI = importVueComp('components/tools', 'BlockUI');
Vue.component('block-ui', blockUI);


Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

