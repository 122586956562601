function importModule (path, name) {
    let t = `${path}/${name}`;
    if (!name) t = path;
    let extraExist;
    let c;
    if (extraExist) {
        let r = require(`@/${c}/${t}.js`).default;
        return r;
    } else {
        let stdExist;
        try {
            stdExist = require.resolveWeak(`@/base/${t}.js`);
        } catch (e) {
        }
        if (stdExist) {
            let r = require(`@/base/${t}.js`).default;
            return r;
        }
    }
}

function importMixinModule (name, path, module) {
    let pathList = fieldsPathList[name];
    let index;
    if (!module && pathList) {
        //import del último mixin de la lista
        index = pathList.length - 1;
        //pathList[index].path = path completo de donde importar
        let p = `${pathList[index].module}/${path}/${pathList[index].path}`;
        let r = importModule(p);
        //si no existe import del std
        if (!r) r = importModule(path, name);
        return r;
    } else if (!pathList) {
        //import del standard, no esta redefinido
        //import name en path
        return importModule(path, name);
    } else if (module && pathList) {
        //import del modulo anterior (extra o no)
        let index = _.findIndex(pathList, (r) => { return r.module== module });
        if (index==0) {
            //si es el último, importo el std
            //import name en path
            return importModule(path, name);
        } else {
            //si i > 0, importo el extra anterior
            //pathList[index - 1].path = path completo de donde importar
            let p = `${pathList[index - 1].module}/${path}/${pathList[index - 1].path}`;
            return importModule(p);
        }
    }
}

function lastMixin (comp, path, type) {
    let obj = compList;
    if (type=='fields') obj = fieldsPathList;
    if (!obj[comp]) {
        return importModule(path, comp);
    }
    return importModule(obj[comp][obj[comp].length - 1].path);
}


function addComponentsPath (key, obj) {
    if (!compList[key]) compList[key] = [];
    compList[key].push(obj);
}


function addFieldsPathList (key, obj) {
    if (!fieldsPathList[key]) fieldsPathList[key] = [];
    let i = _.find(fieldsPathList[key], (r) => {
        return r.module == obj.module && r.path == obj.path;
    })
    if (!i) fieldsPathList[key].push(obj);
}

async function getModuleFieldsOptions (table) {
    let options;
    if (!api.tableOptions[table]) {
        let module = importMixinModule(table, 'tables');
        if (!module) {
            module = importMixinModule('view', 'tables');
        }
        module.table = table;
        options = await module.getTableOptions()
        options.serverSide = module.serverSide;
        api.setTableOptions(table, options);
    } else {
        options = await api.tableOptions[table];
    }
    return options;
}



window.fieldsPathList = {};
window.lastMixin = lastMixin;
window.importMixinModule = importMixinModule;
window.importModule = importModule;
window.addComponentsPath = addComponentsPath;
window.addFieldsPathList = addFieldsPathList;
window.getModuleFieldsOptions = getModuleFieldsOptions;


function getExtraFieldsPaths () {
    let extras = process.env.EXTRAS;
    //extras = ['contacts', 'taxes'];
    for (let i in extras) {
        let extra = extras[i];
        let f = importModule(extra, 'getComponents');
        f();
    }
}

getExtraFieldsPaths()
