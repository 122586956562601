<template>
    <section class="home-section">
        <block-ui v-if="processing"/>
        <section-title title="Settings"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="col-lg-15 my-3">
                    <div class="card rounded-0">
                        <div class="card-body pt-0">
                            <section class="bg-mix">
                                <div class="">
                                    <div class="border-bottom rounded-0">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3" v-for="(v, key) of settings">
                                                <div v-if="types[key]=='number'" class="mx-left">
                                                    <h6 class="text-muted">{{tr(key)}}</h6>
                                                    <number-input
                                                        :v.sync="settings[key]"
                                                        :id="key"
                                                        class="form-control inpc"
                                                    ></number-input>
                                                </div>
                                                <div v-else-if="types[key]=='array'" class="mx-left">
                                                    <h6 class="text-muted">{{tr(key)}}</h6>
                                                    <input
                                                        v-model="arrays[key]"
                                                        name="key"
                                                        class="form-control inpc"
                                                        type="text"
                                                        id="key">
                                                </div>
                                                <div v-else-if="types[key]=='boolean'" class="mx-left">
                                                    <h6 class="text-muted">{{tr(key)}}</h6>
                                                      <input
                                                        class="form-control inpc w-50"
                                                        type="checkbox"
                                                        :id="key"
                                                        v-model="settings[key]"
                                                        :name="key">
                                                </div>
                                                <div v-else-if="types[key]=='text'" class="mx-left">
                                                    <h6 class="text-muted">{{tr(key)}}</h6>
                                                    <input
                                                        v-model="settings[key]"
                                                        name="key"
                                                        class="form-control inpc"
                                                        type="text"
                                                        id="key">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="add">{{tr('Add Value')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="save">{{tr('Save')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <select-value
            v-if="newValue"
            @save="addNewValue"
            @close="newValue = false"
            text="Select value type"
            :fieldOptions="typesOptions"
        ></select-value>
        <input-value
            v-if="showInput"
            @save="setValueName"
            @close="showInput = false"
            text="Add value name"
        ></input-value>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'settings',
    data () {
        return {
            processing: false,
            arrays: {},
            settings: {},
            newValue: false,
            showInput: false,
            valueName: null,
            valueType: null,
            typesOptions: [
                {value: 'text', label: 'Text'},
                {value: 'number', label: 'Number'},
                {value: 'boolean', label: 'Boolean'},
                {value: 'array', label: 'Array'},
            ],
            labels: {
                status: 'OVBO Integration'
            }
        }
    },
    async mounted () {
        this.processing = true;
        this.settings = await api.get_s3('ui-settings.json');
        for (let key in this.settings) {
            let v = this.settings[key];
            if (Array.isArray(v)) {
                this.arrays[key] = v.join(',')
            }
        }
        this.processing = false;
    },
    computed: {
        types () {
            let res = {};
            for (let key in this.settings) {
                let v = this.settings[key];
                if (Array.isArray(v)) {
                    res[key] = 'array';
                } else if (typeof v == "boolean"){
                    res[key] = 'boolean';
                } else if (!isNaN(v) ){
                    res[key] = 'number';
                } else {
                    res[key] = 'text';
                }
            }
            return res;
        }
    },
    methods: {
        tr (t) {
            if (this.labels[t]) return this.labels[t];
            return tr(t);
        },
        async save () {
            this.processing = true;
            for (let key in this.settings) {
                let v = this.settings[key];
                if (Array.isArray(v)) {
                    this.settings[key] = this.arrays[key].split(',');
                }
            }
            let res = await api.save_s3('ui-settings.json', this.settings);
            this.processing = false;
        },
        add () {
            this.newValue = true;
            //this.showInput = true;
        },
        addNewValue (valueType) {
            this.valueType = valueType;
            this.newValue = false;
            setTimeout(()=> {this.showInput = true}, 500);
        },
        setValueName (value) {
            let v;
            if (this.valueType=='boolean') v = false;
            if (this.valueType=='number') v = 0;
            if (this.valueType=='text') v = '';
            if (this.valueType=='array') {
                this.arrays[value] = '';
                this.arrays = Object.assign({}, this.arrays);
            }
            console.log(v, this.valueType, this.valueType=='boolean')
            this.settings[value] = v;
            this.settings = Object.assign({}, this.settings);
        }
    },
}
</script>

