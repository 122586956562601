
import store from '@/store/store';
import Vue from 'vue';
var EventBus = require('@/tools/event-bus').default;

class VueTools {

    async getObjectFromStore (table, id) {
        let res;
        if (store.state.tables.tables[table]) {
            let res = _.find(store.state.tables.tables[table], (r) => r.id == id);
            if (res) {
                let sync_version = await api.get('/api/get_sync_version/' + table + '/' + id );
                if (sync_version && sync_version != res.sync_version) {
                    let res2 = await api.get('/api/' + table + '/' + id);
                    if (res2)  {
                        res2.original_id = id;
                        this.updateToStore(table, res2);
                        return res2;
                    }
                } else {
                    return res;
                }
            }
        }
        res = await api.get('/api/' + table + '/' + id);
        if (res) return res;
    }

    getFromStore (table) {
        if (store.state.tables.tables[table]!=null) {
            return store.state.tables.tables[table];
        }
    }

    setTableToStore (table, values) {
        store.commit('setTable', {table: table, values: values});
    }

    updateToStore (table, record) {
        store.commit('updateTable', {table: table, record: record});
    }

    removeFromStore (table, id) {
        store.commit('removeRecord', {table: table, id: id});
    }

    vueTableText () {
        return {
           count: tr("Showing {from} to {to} of {count} records|{count} records|One record"),
           first: tr("First"),
           last: tr("Last"),
           filter: tr("Filtro"),
           filterPlaceholder: tr("Search"),
           limit: tr("Records"),
           page: tr("Page"),
           noResults: tr("Not results"),
           filterBy: "Filter by {column}",
           loading: tr('Cargando...'),
           defaultOption: tr('Select {column}'),
           columns: tr('Columns')
        }
    }

    setProcessing (value) {
        store.commit('setProcessing', value);
    }

    get processing () {
        return store.state.main.processing;
    }

    showDependencies (self, id, tableName) {
        let router = require('@/router/index').default;
        let route = router.resolve({
            name: 'reports/dependencies',
            params: {
                tableName: tableName,
                id: id
            }
        })
        window.open(route.href, '_blank');
    }

    vueSet (object, key, value) {
        Vue.set(object, key, value);
    }

    getCurrentUser () {
        return store.state.main.me;
    }

    get tablesStore () {
        return store.state.tables.tables;
    }

    get mainStore () {
        return store.state.main;
    }

    setModified (value) {
        if (store.state.modified != value) {
            store.commit('setModified', value);
        }
    }

    async action (self, params, actionName, args) {
        let list = await self.$store.dispatch('callAction', {params, action: actionName, args})
        EventBus.$emit('update-record', (record) => { vueTools.updateRecordValues(record, list) });
    }

    async change (self, params, fieldName, value) {
        let list = await self.$store.dispatch('callAction', {params, action: 'setValue', args: {fieldName, value}})
        EventBus.$emit('update-record', (record) => { vueTools.updateRecordValues(record, list)});
    }

    async addRow (self, params, fieldName, values) {
        let list = await self.$store.dispatch('callAction', {params, action: 'addRow', args: {fieldName, values}})
        EventBus.$emit('update-record', (record) => { vueTools.updateRecordValues(record, list) });
    }

    async removeRow (self, params, fieldName, rowNr) {
        let list = await self.$store.dispatch('callAction', {params, action: 'removeRow', args: {fieldName, rowNr}})
        EventBus.$emit('update-record', (record) => { vueTools.updateRecordValues(record, list) });
    }

    async closeRow (self, params, fieldName, rowNr) {
        let list = await self.$store.dispatch('callAction', {params, action: 'closeRow', args: {fieldName, rowNr}})
        EventBus.$emit('update-record', (record) => { vueTools.updateRecordValues(record, list) });
    }

    updateRecordValues (record, list) {
        if (!list) return;
        for (let p of list) {
            vueTools.updateValue(record, p);
        }
    }

    updateValue (record, {params, value, action}) {
        let v;
        if (Array.isArray(value)) {
            v = tools.plainArray(value);
        } else if (typeof value == 'object') {
            v = tools.plainObject(value);
        } else {
            v = value;
        }
        let obj = record;
        for (let i in params) {
            let p = params[i];
            if (i<params.length-1) {
                obj = obj[p];
            }
        }
        let fieldName = params[params.length - 1];
        if (obj) {
            if (action == 'set') {
                obj[fieldName] = v;
            } else if (action == 'push') {
                obj.push(v);
            } else if (action == 'remove') {
                obj.splice(fieldName, 1);
            }
        }
    }

}

window.vueTools = new VueTools();
