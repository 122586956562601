<template>
    <section class="home-section">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <report-window
                endpoint="/reports/to_process"
                :perPage="100"
                :fields="fields"
                :title="title"
                :headers="headers"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
            ></report-window>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
const moment = require("moment");
export default {
    name: "to-process-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
    },
    mounted: function() {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
    },
    data() {
        return {
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: "FlightFromDate",
                    editor: "date",
                    label: "Flight Date From",
                    columns: 2,
                    required: true,
                },
                {
                    name: "FlightToDate",
                    editor: "date",
                    label: "Flight Date To",
                    columns: 2,
                    required: true,
                },
                {
                    name: "Origin",
                    editor: "text",
                    label: "From",
                    columns: 2
                },
                {
                    name: "Destination",
                    editor: "text",
                    label: "To",
                    columns: 2
                },
                {
                    name: "FileDate",
                    editor: "date",
                    label: "File Date",
                    columns: 2,
                    required: true,
                },
            ],
            title: "To Process",
            headers: {
            },
            templates: [
                {
                    name: "flightdate",
                    callback: function(row) {
                        if (!row.flightdate) return "";
                        return moment(row.flightdate).format("DD/MM/YYYY");
                    }
                },
            ],
            columnClick: {
                number: this.showFlight,
            },
            fieldClasses: {
                number: function(row) {
                    return 'cursor-pointer-report';
                },
            },
        };
    },
    methods: {
        showFlight (row) {
            this.flight = row;
        }
    }
};
</script>
