
function getComp () {
    let res = {};
    return res;
}


function importMixinComponent (module, comp) {
    let obj = compList;
    let index = _.findIndex(obj[comp], (r) => { return r.module== module });
    if (index==0 && path) {
        return importModule(null, comp);
    }
    return importModule(obj[comp][index - 1].path);
}

window.compList = getComp();
window.importMixinComponent = importMixinComponent;

