import View from '@/base/tables/view';

class FlightView extends View {

    //static table = 'flight';

    static options = {
        serverSide: true,
        canAdd: false,
    }

    static async getTableOptions (options) {
        return super.getTableOptions(this.options)
    }
}

export default FlightView;
