
const main = {
    state: {
        modified: false,
        user: null,
        loaded: false,
        processing: false,
        toPath: null,
        callConfirmClose: false,
        search: null,
        tablePage: null,
        tableOrderBy: {ascending: true, column: null},
        adminActions: null,
        salesActions: null,
        operActions: null,
        managerActions: null,
        baseCurrency: null,
        record: null,
        modalRecord: null
    },
    mutations: {
        setUser (state, user) {
            state.user = Object.assign({}, user);
            state.adminActions = tools.canAccess(user, 'api','GET', 'admin_actions', false);
            state.salesActions =  tools.canAccess(user, 'api','GET', 'sales_actions', false);
            state.operActions =  tools.canAccess(user, 'api','GET', 'oper_actions', false);
            state.managerActions =  tools.canAccess(user, 'api','GET', 'manager_actions', false);
        },
        setProcessing (state, value) {
            state.processing = value;
        },
        setModified (state, value) {
            state.modified = value;
        },
        setCallConfirmClose (state, value) {
            state.callConfirmClose = value;
        },
        setToPath (state, value) {
            state.toPath = value;
        },
        setSearch (state, value) {
            state.search = value;
        },
        setTablePage (state, value) {
            state.tablePage = value;
        },
        setTableOrderBy (state, value) {
            state.tableOrderBy = value;
        },
        initRecord (state, {record, modal}) {
            if (modal) {
                state.modalRecord = record;
                return;
            }
            state.record = record;
        },
    },
    getters: {
        modified: state => {
            return state.modified;
        },
    },
    actions: {
        async callAction ({commit, state}, {params, action, args}) {
            let obj = state.record;
            if (params) {
                for (let i in params) {
                    let p = params[i];
                    if (i<params.length) {
                        obj = obj[p];
                    } else {
                        //console.log('stop')
                    }
                }
            }
            if (obj && action) {
                let r = await obj[action](args);
                return r;
            }

        },
    },

}

export default main;
